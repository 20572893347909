import "../style/style.css";
import React from 'react'
import Sun from "../img/sun.png"
import Moon from "../img/moon.png"

const Toggle = () => {
  return (
    <div className="t">
      
    </div>
  )
}

export default Toggle
